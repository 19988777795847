import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

// const rows = [
//   { id: 1, col1: "Hello", col2: "World" },
//   { id: 2, col1: "DataGridPro", col2: "is Awesome" },
//   { id: 3, col1: "MUI", col2: "is Amazing" },
//   { id: 4, col1: "MUI", col2: "is Amazing" },
//   { id: 5, col1: "MUI", col2: "is Amazing" },
//   { id: 6, col1: "MUI", col2: "is Amazing" },
//   { id: 7, col1: "MUI", col2: "is Amazing" },
//   { id: 8, col1: "MUI", col2: "is Amazing" },
//   { id: 9, col1: "MUI", col2: "is Amazing" },
//   { id: 10, col1: "MUI", col2: "is Amazing" },
//   { id: 11, col1: "MUI", col2: "is Amazing" },
//   { id: 12, col1: "MUI", col2: "is Amazing" },
//   { id: 15, col1: "MUI", col2: "is Amazing" },
//   { id: 14, col1: "MUI", col2: "is Amazing" },
//   { id: 13, col1: "MUI", col2: "is Amazing" },
// ];

// const columns = [
//   { field: "col1", headerName: "Column 1", width: 150 },
//   { field: "col2", headerName: "Column 2", width: 150 },
// ];

export default function Datagrid({ columns, data }) {
  return (
    <div style={{ height: "500px", width: "100%" }}>
      <DataGrid
        rows={data}
        getRowId={(r) => r._id}
        columns={columns}
        rowsPerPageOptions={[10, 20, 30, 50]}
        initialState={{
          filter: {
            filterModel: {
              items: [],
            },
          },
        }}
        // disableColumnFilter
        // disableColumnSelector
        // disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        disableSelectionOnClick={true}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
    </div>
  );
}
