import React, { useEffect, useState } from "react";
import Datagrid from "../components/shared/Datagrid";
import {
  Button,
  Grid,
  Typography,
  Modal,
  Box,
  TextField,
  
 
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { MuiFileInput } from "mui-file-input";
import axios from "axios";
import toast from "react-hot-toast";
import { fileURL } from "../context/constants";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
  height: "70vh",
  width: "60vw",
  overflowY: "auto",
};
const IntroScreens = () => {
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [fileError, setFileError] = useState(false);
  const [drivers, setScreens] = React.useState([]);
  const [driverDetails, setDriverDetails] = React.useState();
  const [adding, setAdding] = useState(false);
  const [id, setId] = useState();
  const [order, setOrder] = useState({
    value: "",
    error: false,
    helperText: "",
  });

  const [heading, setHeading] = useState({
    value: "",
    error: false,
    helperText: "",
  });

  const [text, setText] = useState({
    value: "",
    error: false,
    helperText: "",
  });

  const handleClose = () => {
    setOpen(false);
  };
  const columns = [
    {
      headerName: "Order",
      field: "order",
      sortable: true,
      width: 100,
    },
    { field: "heading", headerName: "Heading", width: 150 },
    { field: "text", headerName: "Text", width: 225 },
    {
      headerName: "Image",
      field: "image",
      sortable: true,
      width: 125,
      renderCell: (params) => (
        <a href={`${fileURL}${params.row.image}`} target="_blank">
          <Button variant="outlined" color="success">
            Image
          </Button>
        </a>
      ),
    },
    {
      headerName: "Update",
      field: "id",
      sortable: false,
      width: 125,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            handleOpen(params.row);
          }}
        >
          Update
        </Button>
      ),
    },
    {
      headerName: "Delete",
      field: "_id",
      sortable: false,
      width: 125,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            // alert(params.row.id);
            handleDelete(params.row._id);
          }}
        >
          Delete
        </Button>
      ),
    },
  ];
  const handleFileChange = (newFile) => {
    setFileError(false);
    setFile(newFile);
  };

  const openAddModal = (data, isUpdate) => {
    setOrder({
      value: "",
      error: false,
      helperText: "",
    });
    setHeading({
      value: "",
      error: false,
      helperText: "",
    });
    setText({
      value: "",
      error: false,
      helperText: "",
    });

    setFile(null);

    setOpen(true);
    setAdding(true);
  };
  const handleOpen = (data, isUpdate) => {
    // console.log("data--->", data);
    setOrder({
      value: data.order,
      error: false,
      helperText: "",
    });
    setText({
      value: data.text,
      error: false,
      helperText: "",
    });
    setHeading({
      value: data.heading,
      error: false,
      helperText: "",
    });
    setAdding(false);
    setId(data._id);
    setOpen(true);
  };
  const getData = () => {
    toast.loading("fetching data", { id: "data" });

    axios
      .get("/splashscreens")
      .then(({ data }) => {
        console.log(data);
        setScreens(data.screens);
        toast.success("Data Updated", { id: "data" });
      })
      .catch(() => {
        toast.error("Error Fetching Data", { id: "data" });
      });
  };
  useEffect(() => {
    getData();
  }, []);

  const handleSubmit = (event) => {
    // validation
    if (!order.value) {
      let tmp = { ...order };
      tmp.error = true;
      tmp.helperText = "Provide a valid order";
      return setOrder(tmp);
    }
    if (!heading.value) {
      let tmp = { ...heading };
      tmp.error = true;
      tmp.helperText = "Provide a heading";
      return setHeading(tmp);
    }
    if (!text.value) {
      let tmp = { ...text };
      tmp.error = true;
      tmp.helperText = "Provide a text";
      return setText(tmp);
    }

    // console.log(id);
    let formData = new FormData();
    formData.append("image", file);
    formData.append("order", order.value);
    formData.append("heading", heading.value);
    formData.append("text", text.value);

    if (adding) {
      if (!file) {
        return setFileError(true);
      }
      toast.loading("Adding Intro Screen", { id: "saA" });
      axios
        .post("/addSplashScreen", formData)
        .then(({ data }) => {
          toast.success("Intro Screen Added", { id: "saA" });
          getData();
          handleClose();
        })
        .catch(() => {
          toast.error("Unable to add Intro Screen", { id: "saA" });
        });

      setAdding(false);
    } else {
      formData.append("id", id);

      toast.loading("Updating Intro Screen", { id: "sa" });
      axios
        .post("/updateSplashScreen", formData)
        .then(({ data }) => {
          toast.success("Intro Screen Updated", { id: "sa" });

          // console.log(data);
          // setScreens(data.subAdmins);
          getData();
          handleClose();
        })
        .catch(() => {
          toast.error("Unable to update Intro Screen", { id: "sa" });
        });
    }
  };

  const handleDelete = (id) => {
    toast.loading("Deleting Intro Screen", { id: "DSA" });
    axios
      .get("/deleteSplashScreen/".concat(id))
      .then(({ data }) => {
        toast.success("Intro Screen Deleted", { id: "DSA" });

        getData();
      })
      .catch(() => {
        toast.error("Unable to delete Intro Screen", { id: "DSA" });
      });
  };

  return (
    <>
      <Grid container justifyContent="space-between">
        {/* Heading Grid */}
        <Grid item>
          <Typography variant="h5" fontWeight="bold" marginBottom={3}>
            Sub Admins
          </Typography>
        </Grid>
        {/* Search Bar and Button Grid */}
        <Grid item>
          <Button variant="contained" onClick={openAddModal}>
            Add
          </Button>
        </Grid>
      </Grid>
      {/* Modal  */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* Modal Content */}
        <Box sx={style}>
          {/* <FormControl onSubmit={handleSubmit}> */}
          <Grid
            container
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5" fontWeight="bold" marginBottom={4}>
                Driver Details
              </Typography>
            </Grid>
            <Grid>
              <Button sx={{ color: "black" }} onClick={handleClose}>
                <CloseOutlined />
              </Button>
            </Grid>
          </Grid>

          {/*  Text Grids */}
          <Grid
            container
            width={"100%"}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={4}>
              <Typography variant="body2" fontWeight="bold" marginY="20px">
                Order
              </Typography>
            </Grid>
            <Grid>
              <TextField
                size="small"
                id="outlined-basic"
                value={order.value}
                type="number"
                onChange={(e) =>
                  setOrder({
                    ...order,
                    value: e.target.value,
                    error: false,
                  })
                }
                helperText={order.helperText}
                error={order.error}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid
            container
            width={"100%"}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={4}>
              <Typography variant="body2" fontWeight="bold" marginY="20px">
                Heading
              </Typography>
            </Grid>
            <Grid>
              <TextField
                size="small"
                id="outlined-basic"
                variant="outlined"
                value={heading.value}
                onChange={(e) =>
                  setHeading({
                    ...heading,
                    value: e.target.value,
                    error: false,
                  })
                }
                helperText={heading.helperText}
                error={heading.error}
              />
            </Grid>
          </Grid>
          <Grid
            container
            width={"100%"}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={4}>
              <Typography variant="body2" fontWeight="bold" marginY="20px">
                Text
              </Typography>
            </Grid>
            <Grid>
              <TextField
                size="small"
                id="outlined-basic"
                value={text.value}
                onChange={(e) =>
                  setText({
                    ...text,
                    value: e.target.value,
                    error: false,
                  })
                }
                helperText={text.helperText}
                error={text.error}
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Grid
            container
            width={"100%"}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={4}>
              <Typography variant="body2" fontWeight="bold" marginY="20px">
                Image
              </Typography>
            </Grid>
            <Grid item sx={2}>
              <MuiFileInput
                value={file}
                onChange={handleFileChange}
                variant="outlined"
                size="small"
                sx={{ width: 225 }}
                helperText="Upload Image"
                error={fileError}
              />
            </Grid>
          </Grid>
          <Grid container columnGap={5} marginBottom="50px">
            <Typography color="error">{}</Typography>
          </Grid>
          {/* Add Button */}
          <Grid container justifyContent="center">
            <Button
              variant="contained"
              disabled={driverDetails?.isVerified}
              sx={{
                width: "300px",
              }}
              type="submit"
              onClick={handleSubmit}
            >
              Add
            </Button>
          </Grid>
          {/* </Box> */}
          {/* </FormControl> */}
        </Box>
      </Modal>

      <Datagrid data={drivers} columns={columns} />
    </>
  );
};

export default IntroScreens;
