import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Outlet, useNavigate } from "react-router-dom";
import {
  AccountCircle,
  Logout,
  Person,
} from "@mui/icons-material";
import { Button, Container } from "@mui/material";
import toast from "react-hot-toast";
import { useAuth } from "../../context/AuthContext";

const drawerWidth = 240;

export default function SubAdminNav() {
  const navigate = useNavigate();
  const auth = useAuth();
  const handleLogout = async () => {
    try {
      toast.loading("Signing In", { id: "login" });
      await auth?.logout();
      toast.success("Logged out Successfully", { id: "login" });
      console.log(auth.role);
    } catch (error) {
      console.log(error);
      toast.error("Error logging out", { id: "login" });
    }
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            ICE Admin
          </Typography>
          <Button
            color="inherit"
            sx={{ marginLeft: "auto" }}
            onClick={handleLogout}
          >
            {"LOGOUT"}
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          {/* <List> */}
          <Divider />
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                navigate("/subAdmin/riders");
              }}
            >
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText primary={"Riders"} />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                navigate("/subAdmin/drivers");
              }}
            >
              <ListItemIcon>
                <AccountCircle />
              </ListItemIcon>
              <ListItemText primary={"Drivers"} />
            </ListItemButton>
          </ListItem>
          {/* </List> */}

          <Divider />
          <ListItem disablePadding>
            <ListItemButton onClick={handleLogout}>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText primary={"Logout"} />
            </ListItemButton>
          </ListItem>
          <Divider />
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Container>
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
}
