import React, { useEffect, useState } from "react";
import Datagrid from "../components/shared/Datagrid";
import {
  Button,
  Grid,
  Typography,
  Modal,
  Box,
  TextField,

} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { MuiFileInput } from "mui-file-input";
import axios from "axios";
import toast from "react-hot-toast";
import { fileURL } from "../context/constants";
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
  height: "70vh",
  width: "60vw",
  overflowY: "auto",
};
const SubAdmins = () => {
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [drivers, setDrivers] = React.useState([]);
  const [adding, setAdding] = useState(false);
  const [id, setId] = useState();
  const [email, setEmail] = useState({
    value: "",
    error: false,
    helperText: "",
  });

  const [password, setPassword] = useState({
    value: "",
    error: false,
    helperText: "",
  });

  const [firstName, setFirstName] = useState({
    value: "",
    error: false,
    helperText: "",
  });
  const [lastname, setLastname] = useState({
    value: "",
    error: false,
    helperText: "",
  });
  const [mobile, setMobile] = useState({
    value: "",
    error: false,
    helperText: "",
  });
  const handleClose = () => {
    setOpen(false);
  };
  const columns = [
    { field: "name", headerName: "Name", width: 150 },
    { field: "lastname", headerName: "Last Name", width: 150 },
    { field: "email", headerName: "Email", width: 150 },
    { field: "phone", headerName: "Phone", width: 150 },
    {
      headerName: "Registered At",
      field: "createdAt",
      sortable: false,
      width: 175,
      renderCell: (params) => (
        <Typography variant="body">
          {new Date(params.row.createdAt).toLocaleString()}
        </Typography>
      ),
    },
    {
      headerName: "Update",
      field: "id",
      sortable: false,
      width: 125,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            handleOpen(params.row);
          }}
        >
          Update
        </Button>
      ),
    },
    {
      headerName: "Delete",
      field: "_id",
      sortable: false,
      width: 125,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            // alert(params.row.id);
            handleDelete(params.row._id);
          }}
        >
          Delete
        </Button>
      ),
    },
  ];
  const handleFileChange = (newFile) => {
    setFile(newFile);
  };

  const openAddModal = (data, isUpdate) => {
    setFirstName({
      value: "",
      error: false,
      helperText: "",
    });
    setLastname({
      value: "",
      error: false,
      helperText: "",
    });
    setEmail({
      value: "",
      error: false,
      helperText: "",
    });
    setMobile({
      value: "",
      error: false,
      helperText: "",
    });
    setPassword({
      value: "",
      error: false,
      helperText: "",
    });

    setOpen(true);
    setAdding(true);
  };
  const handleOpen = (data, isUpdate) => {
    setOpen(true);
    // console.log("data--->", data);
    setFirstName({
      value: data.name,
      error: false,
      helperText: "",
    });
    setLastname({
      value: data.lastname,
      error: false,
      helperText: "",
    });
    setEmail({
      value: data.email,
      error: false,
      helperText: "",
    });
    setMobile({
      value: data.phone,
      error: false,
      helperText: "",
    });
    setId(data._id);
    
  };
  const getData = () => {
    // toast.loading("fetching data", { id: "data" });

    axios
      .get("/subadmins")
      .then(({ data }) => {
        // console.log(data);
        setDrivers(data.subAdmins);
        // toast.success("Data Loaded", { id: "data" });
      })
      .catch(() => {
        // toast.error("Error Fetching Data", { id: "data" });
      });
  };
  useEffect(() => {
    getData();
  }, []);

  const handleSubmit = (event) => {
    // validation
    if (!firstName.value) {
      let tmp = { ...firstName };
      tmp.error = true;
      tmp.helperText = "Provide a valid name";
      return setFirstName(tmp);
    }
    if (!lastname.value) {
      let tmp = { ...lastname };
      tmp.error = true;
      tmp.helperText = "Provide a valid name";
      return setLastname(tmp);
    }
    if (!mobile.value) {
      let tmp = { ...mobile };
      tmp.error = true;
      tmp.helperText = "Provide a valid name";
      return setMobile(tmp);
    }
    // if (!password.value) {
    //   let tmp = { ...password };
    //   tmp.error = true;
    //   tmp.helperText = "Provide a valid password";
    //   return setPassword(tmp);
    // }
    // if (!file) {
    //   let tmp = { ...firstName };
    //   tmp.error = true;
    //   tmp.helperText = "Provide a valid name";
    //   return setFirstName(tmp);
    // }
    // console.log(id);
    let formData = new FormData();
    formData.append("image", file);
    formData.append("email", email.value);
    formData.append("name", firstName.value);
    formData.append("lastname", lastname.value);
    formData.append("phone", mobile.value);
    formData.append("password", password.value);

    if (adding) {
      toast.loading("Adding SubAdmin", { id: "saA" });
      axios
        .post("/addSubAdmin", formData)
        .then(({ data }) => {
          toast.success("SubAdmin Added", { id: "saA" });

          getData();
          handleClose();
        })
        .catch(() => {
          toast.error("Unable to add SubAdmin", { id: "saA" });
        });
    } else {
      formData.append("id", id);

      toast.loading("Updating SubAdmin", { id: "sa" });
      axios
        .post("/updateSubAdmin", formData)
        .then(({ data }) => {
          toast.success("SubAdmin Updated", { id: "sa" });

          // console.log(data);
          // setDrivers(data.subAdmins);
          getData();
          handleClose();
        })
        .catch(() => {
          toast.error("Unable to update SubAdmin", { id: "sa" });
        });
    }
  };

  const handleDelete = (id) => {
    toast.loading("Deleting SubAdmin", { id: "DSA" });
    axios
      .get("/deleteSubAdmin/".concat(id))
      .then(({ data }) => {
        toast.success("SubAdmin Deleted", { id: "DSA" });

        getData();
      })
      .catch(() => {
        toast.error("Unable to delete SubAdmin", { id: "DSA" });
      });
  };

  return (
    <>
      <Grid container justifyContent="space-between">
        {/* Heading Grid */}
        <Grid item>
          <Typography variant="h5" fontWeight="bold" marginBottom={3}>
            Sub Admins
          </Typography>
        </Grid>
        {/* Search Bar and Button Grid */}
        <Grid item>
          <Button variant="contained" onClick={openAddModal}>
            Add
          </Button>
        </Grid>
      </Grid>
      {/* Modal  */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* Modal Content */}
        <Box sx={style}>
          {/* <FormControl onSubmit={handleSubmit}> */}
          <Grid
            container
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5" fontWeight="bold" marginBottom={4}>
                Driver Details
              </Typography>
            </Grid>
            <Grid>
              <Button sx={{ color: "black" }} onClick={handleClose}>
                <CloseOutlined />
              </Button>
            </Grid>
          </Grid>

          {/*  Text Grids */}
          <Grid
            container
            width={"100%"}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={4}>
              <Typography variant="body2" fontWeight="bold" marginY="20px">
                First Name
              </Typography>
            </Grid>
            <Grid>
              <TextField
                size="small"
                id="outlined-basic"
                value={firstName.value}
                onChange={(e) =>
                  setFirstName({
                    ...firstName,
                    value: e.target.value,
                    error: false,
                  })
                }
                helperText={firstName.helperText}
                error={firstName.error}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid
            container
            width={"100%"}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={4}>
              <Typography variant="body2" fontWeight="bold" marginY="20px">
                Last Name
              </Typography>
            </Grid>
            <Grid>
              <TextField
                size="small"
                id="outlined-basic"
                variant="outlined"
                value={lastname.value}
                onChange={(e) =>
                  setLastname({
                    ...lastname,
                    value: e.target.value,
                    error: false,
                  })
                }
                helperText={lastname.helperText}
                error={lastname.error}
              />
            </Grid>
          </Grid>
          <Grid
            container
            width={"100%"}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={4}>
              <Typography variant="body2" fontWeight="bold" marginY="20px">
                Email
              </Typography>
            </Grid>
            <Grid>
              <TextField
                size="small"
                id="outlined-basic"
                value={email.value}
                onChange={(e) =>
                  setEmail({
                    ...email,
                    value: e.target.value,
                    error: !emailRegex.test(e.target.value),
                  })
                }
                helperText={email.helperText}
                error={email.error}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid
            container
            width={"100%"}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={4}>
              <Typography variant="body2" fontWeight="bold" marginY="20px">
                Mobile
              </Typography>
            </Grid>
            <Grid>
              <TextField
                size="small"
                id="outlined-basic"
                variant="outlined"
                value={mobile.value}
                type="number"
                onChange={(e) =>
                  setMobile({
                    ...mobile,
                    value: e.target.value,
                    error: false,
                  })
                }
                helperText={mobile.helperText}
                error={mobile.error}
              />
            </Grid>
          </Grid>
          <Grid
            container
            width={"100%"}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={4}>
              <Typography variant="body2" fontWeight="bold" marginY="20px">
                Password
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                size="small"
                id="outlined-basic"
                variant="outlined"
                type="password"
                value={password.value}
                onChange={(e) =>
                  setPassword({
                    ...password,
                    value: e.target.value,
                    error: false,
                  })
                }
                helperText={password.helperText}
                error={password.error}
              />
            </Grid>
          </Grid>
          <Grid
            container
            width={"100%"}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={4}>
              <Typography variant="body2" fontWeight="bold" marginY="20px">
                Image
              </Typography>
            </Grid>
            <Grid item sx={2}>
              <MuiFileInput
                value={file}
                onChange={handleFileChange}
                variant="outlined"
                size="small"
                sx={{ width: 225 }}
                helperText="Upload Image"
              />
            </Grid>
          </Grid>
          <Grid container columnGap={5} marginBottom="50px">
            <Typography color="error">{}</Typography>
          </Grid>
          {/* Add Button */}
          <Grid container justifyContent="center">
            <Button
              variant="contained"
             
              sx={{
                width: "300px",
              }}
              type="submit"
              onClick={handleSubmit}
            >
              Add
            </Button>
          </Grid>
          {/* </Box> */}
          {/* </FormControl> */}
        </Box>
      </Modal>

      <Datagrid data={drivers} columns={columns} />
    </>
  );
};

export default SubAdmins;
