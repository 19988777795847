import { Route, Routes } from "react-router-dom";
import * as React from "react";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import AdminHome from "./pages/AdminHome";
import SubAdminHome from "./pages/SubAdminHome";
import AdminAuthWrapper from "./components/AdminAuthWrapper";
import SubAdminAuthWrapper from "./components/SubAdminAuthWrapper";
import SubAdminNav from "./components/shared/SubAdminNav";
import AdminNav from "./components/shared/AdminNav";
import SubAdmins from "./pages/SubAdmins";
import Drivers from "./pages/Drivers";
import Riders from "./pages/Riders";
import IntroScreens from "./pages/IntroScreens";

function App() {
  return (
    <main>
      {/* <Navbar /> */}
      <Routes>
        <Route index element={<Login />} />
        <Route element={<AdminAuthWrapper />}>
          <Route path="admin" element={<AdminNav />}>
            <Route index element={<AdminHome />} />
            <Route path="subadmins" element={<SubAdmins />} />
            <Route path="drivers" element={<Drivers />} />
            <Route path="riders" element={<Riders />} />
            <Route path="intro-screens" element={<IntroScreens />} />
          </Route>
        </Route>

        {/* Sub Admin Routes */}
        <Route element={<SubAdminAuthWrapper />}>
          <Route path="subAdmin" element={<SubAdminNav />}>
            <Route index element={<SubAdminHome />} />
            <Route path="drivers" element={<Drivers />} />
            <Route path="riders" element={<Riders />} />
          </Route>
        </Route>

        {/* not found page */}
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </main>
  );
}

export default App;
