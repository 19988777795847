import React from "react";
import { useAuth } from "../context/AuthContext";
import { Outlet, Navigate, useLocation } from "react-router-dom";

const AdminAuthWrapper = () => {
  const auth = useAuth();
  const location = useLocation();
  return auth.user && auth.role === "admin" ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default AdminAuthWrapper;
