import React, { useEffect } from "react";
import Datagrid from "../components/shared/Datagrid";
import {
  Button,
  Grid,
  Typography,
  Modal,
  Box,
  Stack,
  Divider,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { MuiFileInput } from "mui-file-input";
import axios from "axios";
import toast from "react-hot-toast";
import { fileURL } from "../context/constants";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
  height: "70vh",
  overflowY: "auto",
};
const Drivers = () => {
  const [open, setOpen] = React.useState(true);
  const [file, setFile] = React.useState(null);
  const [drivers, setDrivers] = React.useState([]);
  const [carData, setCarData] = React.useState({});
  const [driverDetails, setDriverDetails] = React.useState();
  const [modalData, setModalData] = React.useState(null);

  const handleClose = () => {
    setOpen(false);
  };
  const columns = [
    { field: "name", headerName: "Name", width: 150 },
    { field: "email", headerName: "Email", width: 150 },
    { field: "phone", headerName: "Phone", width: 150 },
    { field: "gender", headerName: "Gender", width: 150 },
    {
      headerName: "Registered At",
      field: "_id",
      sortable: false,
      width: 175,
      renderCell: (params) => (
        <Typography variant="body">
          {new Date(params.row.createdAt).toLocaleString()}
        </Typography>
      ),
    },
    {
      headerName: "Details",
      field: "id",
      sortable: false,
      width: 125,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            // alert(params.row.id);
            setModalData(params.row);
            handleOpen(params.row._id);
          }}
        >
          Details
        </Button>
      ),
    },
  ];
  const handleOpen = (id) => {
    // toast.loading("Loading", { id: "drivers" });
    axios
      .get("/car-data/".concat(id))
      .then(({ data }) => {
        console.log(data);
        setCarData(data.car);
        setDriverDetails(data.driverDetails);
        console.log(driverDetails);
        // toast.success("Data Loaded", { id: "dri¿vers" });

        setOpen(true);
      })
      .catch((err) => {
        // toast.error("Unable to load Data", { id: "drivers" });
      });
  };

  useEffect(() => {
    toast.loading("fetching data", { id: "data" });

    axios
      .get("/drivers")
      .then(({ data }) => {
        // console.log(data);
        toast.success("Data Loaded", { id: "data" });

        setDrivers(data.drivers);
      })
      .catch(() => {
        toast.error("Error Fetching Data", { id: "data" });
      });
  }, []);

  const verifyDriver = async (id) => {
    toast.loading("Verifying", { id: "drivers" });

    axios
      .get("/verifyDriver/".concat(id))
      .then(({ data }) => {
        console.log(data);
        setDrivers(data.drivers);
        toast.success("Verified Successfully", { id: "drivers" });
        handleClose();
      })
      .catch(() => {
        toast.error("Unable to Verify", { id: "drivers" });
      });
  };
  return (
    <>
      <Grid container justifyContent="space-between">
        {/* Heading Grid */}
        <Grid item>
          <Typography variant="h5" fontWeight="bold" marginBottom={3}>
            Drivers
          </Typography>
        </Grid>
        {/* Search Bar and Button Grid */}
        <Grid item>
          {/* <Button variant="contained" onClick={() => {}}>
            Add
          </Button> */}
        </Grid>
      </Grid>

      {/* Modal  */}
      {modalData ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {/* Modal Content */}
          <Box sx={style}>
            <Grid container justifyContent="space-between">
              <Grid>
                <Typography variant="h5" fontWeight="bold" marginBottom={4}>
                  Driver Details
                </Typography>
              </Grid>
              <Grid>
                <Button sx={{ color: "black" }} onClick={handleClose}>
                  <CloseOutlined />
                </Button>
              </Grid>
            </Grid>

            {/*  Text Grids */}
            <Grid>
              <Stack
                direction="row"
                justifyContent="space-between"
                margin="20px 0 10px 0"
                spacing="20px"
              >
                <Typography fontSize="16px" fontWeight="bold">
                  Email
                </Typography>
                <Typography fontSize="16px">{modalData.email}</Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                margin="20px 0 10px 0"
                spacing="20px"
              >
                <Typography fontSize="16px" fontWeight="bold">
                  Phone
                </Typography>
                <Typography fontSize="16px">{modalData.phone}</Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                margin="20px 0 10px 0"
                spacing="20px"
              >
                <Typography fontSize="16px" fontWeight="bold">
                  Name
                </Typography>
                <Typography fontSize="16px">{modalData.name}</Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                margin="20px 0 10px 0"
                spacing="20px"
              >
                <Typography fontSize="16px" fontWeight="bold">
                  City
                </Typography>
                <Typography fontSize="16px">{modalData.city}</Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                margin="20px 0 10px 0"
                spacing="20px"
              >
                <Typography fontSize="16px" fontWeight="bold">
                  Street
                </Typography>
                <Typography fontSize="16px">{modalData.street}</Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                margin="20px 0 10px 0"
                spacing="20px"
              >
                <Typography fontSize="16px" fontWeight="bold">
                  District
                </Typography>
                <Typography fontSize="16px">{modalData.district}</Typography>
              </Stack>
              <Divider />
              {/* car details */}
              {/* <Typography marginY="20px"> */}

              <h3>Car Details</h3>
              {/* </Typography> */}

              <Stack
                direction="row"
                justifyContent="space-between"
                margin="20px 0 10px 0"
                spacing="20px"
              >
                <Typography fontSize="16px" fontWeight="bold">
                  Model
                </Typography>
                <Typography fontSize="16px">{carData?.carModel}</Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                margin="20px 0 10px 0"
                spacing="20px"
              >
                <Typography fontSize="16px" fontWeight="bold">
                  Category
                </Typography>
                <Typography fontSize="16px">{carData?.category}</Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                margin="20px 0 10px 0"
                spacing="20px"
              >
                <Typography fontSize="16px" fontWeight="bold">
                  Color
                </Typography>
                <Typography fontSize="16px">{carData?.color}</Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                margin="20px 0 10px 0"
                spacing="20px"
              >
                <Typography fontSize="16px" fontWeight="bold">
                  Registration Number
                </Typography>
                <Typography fontSize="16px">
                  {carData?.registrationNumber}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                margin="20px 0 10px 0"
                spacing="20px"
              >
                <Typography fontSize="16px" fontWeight="bold">
                  Car Image
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                margin="20px 0 10px 0"
                spacing="20px"
              >
                <img src={fileURL.concat(carData?.carImage)} width="400px" />
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                margin="20px 0 10px 0"
                spacing="20px"
              >
                <Typography fontSize="16px" fontWeight="bold">
                  Documents
                </Typography>
              </Stack>
              {modalData.files.map(({ file }, index) => (
                <Stack
                  key={index}
                  direction="row"
                  justifyContent="space-between"
                  margin="20px 0 10px 0"
                  spacing="20px"
                >
                  <img
                    src={fileURL.concat(file)}
                    alt="car image"
                    width="400px"
                  />
                </Stack>
              ))}

              {/* <Typography variant="caption">
              {image ? `File name: ${image.name}` : "no files uploaded yet"}
            </Typography> */}
            </Grid>

            <Grid container columnGap={5} marginBottom="50px">
              <Typography color="error">{}</Typography>
            </Grid>

            {/* Add Button */}
            <Grid container justifyContent="center">
              <Button
                variant="contained"
                disabled={driverDetails?.isVerified}
                sx={{
                  width: "300px",
                }}
                onClick={() => {
                  verifyDriver(driverDetails?._id);
                }}
              >
                {driverDetails?.isVerified ? "Verified" : "Verify"}
              </Button>
            </Grid>
          </Box>
        </Modal>
      ) : null}

      <Datagrid data={drivers} columns={columns} />
    </>
  );
};

export default Drivers;
