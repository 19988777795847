import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FormControl } from "@mui/material";
import { useAuth } from "../context/AuthContext";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="/">
        ICE Ride Hailing
      </Link>
      &nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Login() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState({
    email: "",
    error: false,
    helperText: "",
  });
  const [password, setPassword] = useState({
    pass: "",
    error: false,
    helperText: "",
  });
  useEffect(() => {
    if (auth?.role) {
      if (auth.role === "admin") {
        return navigate("/admin");
      }

      if (auth.role === "subAdmin") {
        return navigate("/subAdmin");
      }
    }
  }, [auth, navigate]);
  const handleSubmit = async (event) => {
    event.preventDefault();

    // if(email.email )
    if (emailRegex.test(email.email) && password.pass) {
      try {
        toast.loading("Signing In", { id: "login" });
        await auth?.login(email.email, password.pass);
        toast.success("Signed In Successfully", { id: "login" });
        console.log(auth.role);
      } catch (error) {
        console.log(error);
        toast.error("Invalid Credentials", { id: "login" });
      }
    }

    if (!emailRegex.test(email.email)) {
      setEmail({ ...email, error: true, helperText: "Provide valid email" });
    }
    if (!password.pass) {
      setPassword({
        ...password,
        error: true,
        helperText: "Provide valid password",
      });
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              "url(https://source.unsplash.com/random?wallpapers)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>

            <FormControl>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email.email}
                  onChange={(e) =>
                    setEmail({
                      ...email,
                      email: e.target.value,
                      error: !emailRegex.test(e.target.value),
                    })
                  }
                  helperText={email.helperText}
                  error={email.error}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  value={password.pass}
                  autoComplete="current-password"
                  onChange={(e) =>
                    setPassword({
                      ...password,
                      pass: e.target.value,
                      error: false,
                    })
                  }
                  helperText={password.helperText}
                  error={password.error}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>

                <Grid container></Grid>
                <Copyright sx={{ mt: 5 }} />
              </Box>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
